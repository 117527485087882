import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="notifications"
export default class extends Controller {
  connect () {
    if (!document.documentElement.hasAttribute("data-turbo-preview")) {
      const modal = new window.bootstrap.Modal(this.element);
      modal.show();
    }
  }
}
